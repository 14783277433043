@use './variables' as *;

body,
html {
  width: 100%;
  height: 100%;
  font-family: "Lato", sans-serif;
  background-color: #212121;
}

.app-wrap {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  z-index: 1;

  // background: radial-gradient(
  //   circle,
  //   rgba(238, 100, 255, 1) 0%,
  //   rgba(100, 187, 233, 1) 100%
  // );

  // &::after {
  //   content: " ";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: red;
  //   z-index: -1;
  //   min-height: 100%;

  //   animation-name: app-wrap-overwrap-bg-anim;
  //   animation-duration: 30s;
  //   animation-delay: 0;
  //   animation-timing-function: ease;
  //   animation-iteration-count: infinite;
  //   animation-direction: alternate;
  // }
}

@keyframes app-wrap-overwrap-bg-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

* {
  box-sizing: border-box;
}
