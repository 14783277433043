@use 'sass:map';
@use './variables' as *;

// gutters
//

// [todo]
// 現在は必要そうなものだけベタ書き。
// 後日ひとまとめに書く

.app-u-py-gutter {
  padding-top: $app-sp-gutter !important;
  padding-bottom: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding-top: $app-gutter !important;
    padding-bottom: $app-gutter !important;
  }
}

.app-u-px-gutter {
  padding-right: $app-sp-gutter !important;
  padding-left: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding-right: $app-gutter !important;
    padding-left: $app-gutter !important;
  }
}

.app-u-p-gutter {
  padding: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding: $app-gutter !important;
  }
}

.app-u-m-gutter {
  margin: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin: $app-gutter !important;
  }
}

.app-u-my-gutter {
  margin-top: $app-sp-gutter !important;
  margin-bottom: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin-top: $app-gutter !important;
    margin-bottom: $app-gutter !important;
  }
}

.app-u-mx-gutter {
  margin-right: $app-sp-gutter !important;
  margin-left: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin-right: $app-gutter !important;
    margin-left: $app-gutter !important;
  }
}

.app-u-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.app-u-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.app-u-p-0 {
  padding: 0 !important;
}

.app-u-m-0 {
  margin: 0 !important;
}

.app-u-mb-gutter {
  margin-bottom: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin-bottom: $app-gutter !important;
  }
}

.app-u-pb-gutter {
  padding-bottom: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding-bottom: $app-gutter !important;
  }
}

.app-u-mt-gutter {
  margin-top: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin-top: $app-gutter !important;
  }
}

.app-u-pt-gutter {
  padding-top: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding-top: $app-gutter !important;
  }
}

.app-u-me-gutter {
  margin-right: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin-right: $app-gutter !important;
  }
}

.app-u-pe-gutter {
  padding-right: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding-right: $app-gutter !important;
  }
}

.app-u-ms-gutter {
  margin-left: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin-left: $app-gutter !important;
  }
}

.app-u-ps-gutter {
  padding-left: $app-sp-gutter !important;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding-left: $app-gutter !important;
  }
}

// position
//

// [todo]
// 現在は必要そうなものだけベタ書き。
// 後日ひとまとめに書く

.app-u-position-relative {
  position: relative !important;
}

// order
//

// [todo]
// 現在は必要そうなものだけベタ書き。
// 後日ひとまとめに書く

.app-u-order-1 {
  order: 1 !important;
}

.app-u-order-2 {
  order: 2 !important;
}

.app-u-order-lg-1 {
  @media (min-width: map.get($app-breakpoints, "lg")) {
    order: 1 !important;
  }
}

.app-u-order-lg-2 {
  @media (min-width: map.get($app-breakpoints, "lg")) {
    order: 2 !important;
  }
}

// Width / Height
//

.app-u-w-100 {
  width: 100% !important;
}

.app-u-h-100 {
  height: 100% !important;
}

// Display
//

.app-u-d-flex {
  display: flex !important;
}

// Flex
//

.app-u-justify-content-between {
  justify-content: space-between !important;
}

.app-u-justify-content-end {
  justify-content: end !important;
}

.app-u-align-items-end {
  align-items: flex-end !important;
}

.app-u-d-gap-gutter {
  gap: $app-gutter !important;
}
