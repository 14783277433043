@use 'sass:map';
@use './variables' as *;

.menu-btn {
  position: relative;
  display: inline-block;
  height: 40px;
  background-color: $color-slate-500;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.44);

  @media (min-width: map.get($app-breakpoints, "lg")) {
    height: 64px;
  }

  &::before {
    content: " ";
    position: absolute;
    border-radius: 6px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
        116.9deg,
        rgba(0, 0, 0, 0) 2.63%,
        rgba(0, 0, 0, 0.2) 66.83%
      ),
      $color-slate-500;
  }

  // &:hover::before {
  //   background: linear-gradient(
  //       116.9deg,
  //       rgba(0, 0, 0, 0) 2.63%,
  //       rgba(0, 0, 0, 0.26) 50.83%
  //     ),
  //     $color-slate-500;
  // }

  &__label {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 2;
    line-height: 1;
    color: $color-slate-900;
    text-align: right;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
  }
}
