@use './variables' as *;

.fader {
  -webkit-appearance: none;
  height: 12px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  background: linear-gradient(to right, $color-lime-500, $color-purple-500);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0;

  @mixin fader-handle() {
    background: $color-slate-700;
    width: 16px;
    height: 32px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }

  &::-webkit-slider-thumb {
    @include fader-handle;
    -webkit-appearance: none; // delete default handle style
  }
  &::-moz-range-thumb {
    @include fader-handle;
    border: none; // delete default line
  }
}
