@use './variables' as *;

.measure-leds {
  display: flex;
  gap: 8px;
}

.measure-led {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: $color-slate-100;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 2px 4px rgba(0, 0, 0, 0.25);

  &.--state_active {
    background-color: $color-role-idle;
  }
}
