// Color System
//

// Global Colors

$color-slate-50: #f8fafc;
$color-slate-100: #f1f5f9;
$color-slate-200: #e2e8f0;
$color-slate-300: #cbd5e1;
$color-slate-400: #94a3b8;
$color-slate-500: #64748b;
$color-slate-600: #475569;
$color-slate-700: #334155;
$color-slate-800: #222f45;
$color-slate-900: #192339;
$color-slate-950: #0f172a;

$color-lime-50: #f7fee7;
$color-lime-100: #ecfccb;
$color-lime-200: #d8f99d;
$color-lime-300: #bdf264;
$color-lime-400: #a2e635;
$color-lime-500: #85d016;
$color-lime-600: #64a30d;
$color-lime-700: #4c7c0f;
$color-lime-800: #3e6212;
$color-lime-900: #365314;
$color-lime-950: #1a2e05;

$color-cyan-50: #edfdfe;
$color-cyan-100: #d1f9fc;
$color-cyan-200: #a8f0f9;
$color-cyan-300: #6de4f3;
$color-cyan-400: #28cde6;
$color-cyan-500: #0eb0cc;
$color-cyan-600: #0e8dac;
$color-cyan-700: #13718b;
$color-cyan-800: #195c71;
$color-cyan-900: #194d60;
$color-cyan-950: #0a3242;

$color-purple-50: #faf5ff;
$color-purple-100: #f4e8ff;
$color-purple-200: #ebd5ff;
$color-purple-300: #dab4fe;
$color-purple-400: #c184fc;
$color-purple-500: #a855f7;
$color-purple-600: #9133ea;
$color-purple-700: #7a22ce;
$color-purple-800: #6621a8;
$color-purple-900: #531c87;
$color-purple-950: #370764;

$color-pink-50: #fef1f7;
$color-pink-100: #fee5f2;
$color-pink-200: #ffcbe6;
$color-pink-300: #ffa0cf;
$color-pink-400: #ff65ae;
$color-pink-500: #fd4e9a;
$color-pink-600: #ed1568;
$color-pink-700: #cf074e;
$color-pink-800: #ab0940;
$color-pink-900: #8e0d39;
$color-pink-950: #57001d;

$color-coral-50: #fff2f1;
$color-coral-100: #ffe4e1;
$color-coral-200: #ffccc7;
$color-coral-300: #ffa8a0;
$color-coral-400: #ff6f61;
$color-coral-500: #f84c3b;
$color-coral-600: #e52f1d;
$color-coral-700: #c12314;
$color-coral-800: #a02014;
$color-coral-900: #842218;
$color-coral-950: #480d07;

$color-corn-50: #fefee8;
$color-corn-100: #fefdc3;
$color-corn-200: #fef88a;
$color-corn-300: #fded47;
$color-corn-400: #fadd15;
$color-corn-500: #f2ca08;
$color-corn-600: #ca9804;
$color-corn-700: #a16d07;
$color-corn-800: #85560e;
$color-corn-900: #714612;
$color-corn-950: #422406;

$color-teal-50: #f0fdfa;
$color-teal-100: #ccfbf1;
$color-teal-200: #9af5e3;
$color-teal-300: #5fe9d2;
$color-teal-400: #2fd2bd;
$color-teal-500: #16bba8;
$color-teal-600: #0e9386;
$color-teal-700: #10756c;
$color-teal-800: #125d58;
$color-teal-900: #144d4a;
$color-teal-950: #042f2e;

// Role Colors (Alias)

$color-role-base: $color-slate-900;
$color-role-text: $color-slate-100;
$color-role-select: $color-cyan-400;
$color-role-idle: $color-lime-400;
$color-role-danger: $color-pink-600;

$pad-foundation-active-colors: (
  1: $color-cyan-400,
  2: $color-purple-400,
  3: $color-teal-400,
  4: $color-coral-400,
  5: $color-lime-400,
  6: $color-corn-400,
);

// Spaces

$app-gutter: 12px;
$app-sp-gutter: 8px;
$app-spacer: 16px;

$app-spaces: (
  1: 8px,
  2: 12px,
  3: 16px,
  4: 24px,
  5: 32px,
);

$app-size-spaces: (
  xs: 8px,
  sm: 12px,
  md: 16px,
  lg: 24px,
  xl: 32px,
);

// Sizes
//

$app-max-width: 1216px;

$app-max-widths: (
  sm: 540px,
  md: 704px,
  lg: 1080px,
  xl: 1216px,
);

$app-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1160px,
  xl: 1280px,
);
