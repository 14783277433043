@use 'sass:map';
@use './variables' as *;

.pad {
  $_pad-gutter: 9px;
  $_pad-radius: 6px;
  $_pad-border-size: 1px;

  position: relative;
  width: 100%;
  max-width: 156px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // &.--size_sm {}

  // &.--size_md {}

  // &.--size_lg {}

  &__foundation {
    background-color: $color-slate-900;
    width: calc(100% - $_pad-border-size * 2);
    position: relative;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
    border-radius: $_pad-radius;

    // @at-root .pad.--state_active & {
    //   background-color: red;
    // }

    @each $key, $val in $pad-foundation-active-colors {
      @at-root .pad.--state_active.--color_#{$key} & {
        background-color: $val;
      }
    }

    // 1px大きな背面要素で枠線を表現
    &::after {
      position: absolute;
      top: -#{$_pad-border-size};
      bottom: -#{$_pad-border-size};
      left: -#{$_pad-border-size};
      right: -#{$_pad-border-size};
      background: linear-gradient(0deg, $color-slate-900, $color-slate-700);
      content: "";
      border-radius: $_pad-radius;
      z-index: -1;
    }

    // 正方形を保つためのratio表現
    &::before {
      display: block;
      padding-top: calc(1 / 1 * 100%);
      content: "";

      @media (min-width: map.get($app-breakpoints, "sm")) {
        padding-top: calc(6 / 13 * 100%);
      }

      @media (min-width: map.get($app-breakpoints, "lg")) {
        padding-top: calc(1 / 1 * 100%);
      }
    }
  }

  &__btn-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: calc(100% - $_pad-gutter);
    height: calc(100% - $_pad-gutter);
    z-index: 1;
    background-color: transparent;
    overflow: hidden;
    text-align: right;
  }

  &__btn {
    background-color: $color-slate-700;
    cursor: pointer;
    border-radius: $_pad-radius;
    width: 100%;
    height: 100%;
    border: none;

    // borderを表現する背後の要素
    //
    &::before {
      position: absolute;
      top: -#{$_pad-border-size};
      bottom: -#{$_pad-border-size};
      left: -#{$_pad-border-size};
      right: -#{$_pad-border-size};
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
      opacity: 0.16;
      content: "";
      border-radius: $_pad-radius;
      z-index: -1;
    }

    // 前面の下方向からの影
    //
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      opacity: 0.2;
      content: "";
      z-index: 2;
      border-radius: $_pad-radius;
    }

    @each $key, $val in $pad-foundation-active-colors {
      @at-root .pad.--state_active.--color_#{$key} &::after {
        background: $val;
        filter: blur(20px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        border-radius: 50%;
        opacity: 1;
      }
    }
  }

  &__label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: $color-slate-100;
    font-size: 12px;
    line-height: 1;
  }
}
