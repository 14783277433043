@use 'sass:map';
@use './variables' as *;

.degital-canvas {
  background-color: $color-slate-950;
  border: 1px solid $color-slate-800;
  height: 200px;

  // @media (min-width: map.get($app-breakpoints, "lg")) {
  //   height: 240px;
  // }

  canvas {
    width: 100%;
    height: 100%;
  }
}
