@use './variables' as *;

#root {
  width: 100%;
  height: 100%;
}

.app {
  color: $color-role-text;
  width: 100%;
  height: 100%;
  padding: $app-gutter * 2 $app-gutter;
}

.meta-ttl {
  display: inline-block;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  color: $color-role-text;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.meta-github-link {
  display: inline-block;
  color: #ccc;
  font-size: 0.75rem;
  font-weight: 700;
  transition: 0.3s;
  line-height: 1.2;

  &:hover {
    color: $color-role-text;
  }
}

.head-container {
  width: 100%;
  height: 36px;
}

.pad-group {
  position: relative;
  z-index: 1;
}

// .edit-group {}
