@use 'sass:map';
@use './variables' as *;

.app-container {
  width: 100%;
  max-width: 100%;
  padding-left: $app-sp-gutter;
  padding-right: $app-sp-gutter;
  margin-left: auto;
  margin-right: auto;

  &.--style_app-body {
    background-color: $color-slate-900;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
  }

  @media (min-width: map.get($app-breakpoints, "sm")) {
    max-width: map.get($app-max-widths, "sm");
  }

  @media (min-width: map.get($app-breakpoints, "md")) {
    max-width: map.get($app-max-widths, "md");
  }

  @media (min-width: map.get($app-breakpoints, "lg")) {
    max-width: map.get($app-max-widths, "lg");
  }

  @media (min-width: map.get($app-breakpoints, "xl")) {
    width: map.get($app-max-widths, "xl");
    max-width: map.get($app-max-widths, "xl");
    padding-left: $app-gutter;
    padding-right: $app-gutter;
  }
}

.app-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1 * $app-sp-gutter;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    margin: 0 -1 * $app-gutter;
  }
}

// .app-row > [class^="app-col-"] {
//   flex-grow: 0;
//   flex-shrink: 0;
//   flex-basis: auto;
//   padding: 0 $app-gutter;
// }

@mixin col-style() {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 0 $app-sp-gutter;

  @media (min-width: map.get($app-breakpoints, "xl")) {
    padding: 0 $app-gutter;
  }
}

.app-col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.app-col-auto {
  @include col-style;
  max-width: none;
}

.app-col-12 {
  @include col-style;
  width: 100%;
}

.app-col-6 {
  @include col-style;
  width: 50%;
}

.app-col-4 {
  @include col-style;
  width: 25%;
}

.app-col-1dot5 {
  @include col-style;
  width: 12.5%;
}

.app-col-lg-6 {
  @include col-style;
  @media (min-width: map.get($app-breakpoints, "lg")) {
    width: 50%;
  }
}

.app-col-md-6 {
  @include col-style;
  @media (min-width: map.get($app-breakpoints, "md")) {
    width: 50%;
  }
}
